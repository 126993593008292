// zoom indicator box
.zoom-indicator-box {
  position: absolute;
  z-index: 1806;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  // icon
  i {
    transform: rotate(-45deg);
    opacity: 0.2;
  }

  // animation
  .zoom-in-out {
    animation: zoom-in-zoom-out 2s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      scale: 100%;
    }
    50% {
      scale: 150%;
    }
    100% {
      scale: 100%;
    }
  }
}
