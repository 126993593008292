$color-border: #dee2e6;

.map-controls-cont {
  position: absolute;
  left: 0;
  top: 0px;
  bottom: 0;
  z-index: 1806;
  border-left: 1px solid $color-border;
  width: 250px;
  background-color: #fff;

  .header {
    position: sticky;
    top: 0px;
    z-index: 1;
    width: 100%;
    background-color: white;
  }

  .download-map-btn {
    position: sticky;
    bottom: 0px;
    width: 100%;
    background-color: white;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.popup-media-image {
  max-height: 200px;
  width: 100%;
}
