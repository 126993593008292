@import "./../../theme/variables";

.dropdown {
  &__menu {
    width: 100%;
    border-radius: 5px !important;
  }

  &__button {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: white;
  }

  &__text {
    white-space: normal;
    color: $gray-900;
    margin-right: 5px;

    &--default {
      @extend .dropdown__text;
      // color: $gray-600;
    }
  }

  &-fixed-height {
    max-height: 250px;
    overflow-y: auto;
  }

  &:hover {
    .sub-menu-options {
      display: block;
    }
  }
}

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

// This is not for plainDropdown..
.dropdown_button {
  margin-top: 14px;
  margin-left: 12px;
}
