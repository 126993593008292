.deselect {
  position: absolute;
  right: -2px;
  top: -2px;
  border: 1px solid black;
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.chip-max-width {
  max-width: 250px;
}
