// Themes :: patrika
[data-theme="patrika"] {
  // Variables
  $primary: #ec1f24;
  $secondary: #d3d3d3;
  $link-color: $primary;

  // Custom CSS
  @import "../custom_theme";

  // Branding
  .navbar-brand {
    background: url("./logo.png");
    background-repeat: no-repeat;
    background-size: auto 40px;
  }
}