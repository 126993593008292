@import "./theme/variables";

@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~bootstrap/scss/bootstrap.scss";
@import "leaflet/dist/leaflet.css";
@import "~bootstrap-daterangepicker/daterangepicker.css";

@import "./theme/layout";
@import "./theme/style.scss";

// Custom Themes
@import "./theme/prooh/theme.scss";
@import "./theme/rapport/theme.scss";
@import "./theme/moms/theme.scss";
@import "./theme/bmeg/theme.scss";
@import "./theme/prmadv/theme.scss";
@import "./theme/mantaray/theme.scss";
@import "./theme/advision/theme.scss";
@import "./theme/patrika/theme.scss";
